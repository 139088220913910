<template>
  <div
    class="
      d-flex
      justify-space-around
      fill-height
      flex-md-row flex-column flex-column-reverse
    "
  >
    <!-- Socials -->
    <div class="pa-4 flex-fill">
      <v-card>
        <v-card-title
          class="
            d-flex
            justify-center
            flex-column
            font-weight-bold
            text-lg-h5
            blue-grey--text
          "
        >
          Social Media
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-list>
            <v-list-item
              href="https://facebook.com/biblestudytwo"
              target="_blank"
            >
              <v-list-item-avatar>
                <v-icon x-large color="blue">mdi-facebook</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="text-lg-subtitle-1 font-weight-bold">
                Follow us on Facebook!
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              href="https://instagram.com/biblestudytwo"
              target="_blank"
            >
              <v-list-item-avatar>
                <v-icon x-large color="red">mdi-instagram</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="text-lg-subtitle-1 font-weight-bold">
                Follow us on Instagram!
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              href="https://twitter.com/biblestudytwo"
              target="_blank"
            >
              <v-list-item-avatar>
                <v-icon x-large color="blue">mdi-twitter</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="text-lg-subtitle-1 font-weight-bold">
                Follow us on Twitter!
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </div>
    <!-- Emails -->
    <div class="pa-4 flex-fill">
      <v-card>
        <v-card-title
          class="
            d-flex
            justify-center
            flex-column
            font-weight-bold
            text-lg-h5
            blue-grey--text
          "
        >
          Email Us
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-list>
            <v-list-item href="mailto:director@biblestudytwo.org">
              <v-list-item-content class="text-lg-subtitle-1 font-weight-bold">
                Bob Seymour
              </v-list-item-content>
              <v-list-item-avatar size="50">
                <v-img src="@/assets/bob_tile.png"></v-img>
              </v-list-item-avatar>
            </v-list-item>
            <v-list-item href="mailto:assistantdirector@biblestudytwo.org">
              <v-list-item-content class="text-lg-subtitle-1 font-weight-bold">
                Cole Maupin
              </v-list-item-content>
              <v-list-item-avatar size="50">
                <v-img src="@/assets/cole.webp"></v-img>
              </v-list-item-avatar>
            </v-list-item>
            <v-list-item href="mailto:boys@biblestudytwo.org">
              <v-list-item-content class="text-lg-subtitle-1 font-weight-bold">
                Boy's Group
              </v-list-item-content>
              <v-list-item-avatar>
                <v-icon x-large>mdi-account-group-outline</v-icon>
              </v-list-item-avatar>
            </v-list-item>
            <v-list-item href="mailto:girls@biblestudytwo.org">
              <v-list-item-content class="text-lg-subtitle-1 font-weight-bold">
                Girl's Group
              </v-list-item-content>
              <v-list-item-avatar>
                <v-icon x-large>mdi-account-group-outline</v-icon>
              </v-list-item-avatar>
            </v-list-item>
            <v-list-item href="mailto:treasurer@biblestudytwo.org">
              <v-list-item-content class="text-lg-subtitle-1 font-weight-bold">
                Treasurer
              </v-list-item-content>
              <v-list-item-avatar>
                <v-icon x-large>mdi-currency-usd</v-icon>
              </v-list-item-avatar>
            </v-list-item>
            <v-list-item href="mailto:social@biblestudytwo.org">
              <v-list-item-content class="text-lg-subtitle-1 font-weight-bold">
                Social Media
              </v-list-item-content>
              <v-list-item-avatar>
                <v-icon x-large>mdi-account-multiple-plus-outline</v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "Connect",
};
</script>
